import {
  CanvasIcon,
  DashboardIcon,
  WidgetIcon,
} from "../components/shared/icons/AppIcons";
import { AppStore } from "../stores/app.store";

export const Home = ({ appStore }: { appStore: AppStore }) => (
  <div className="cai-flex cai-h-[100%] cai-items-center cai-justify-center">
    <div className="cai-flex cai-h-[100%] cai-flex-col cai-p-10">
      <div className="cai-min-h-[40%]">
        <p className="cai-text-[40px]">Welcome to Ai Canvas</p>
        <p className="cai-mt-1 cai-text-[20px]">
          Dive into the world of data exploration and dashboard creation powered
          by cutting-edge AI technology. <br />
          Unleash your creativity and insights as you visualize your data like
          never before. <br />
          <br />
          Let's transform information into inspiration!
        </p>
      </div>
      <div className="cai-flex cai-justify-center">
        <div className="cai-grid cai-grid-cols-3 cai-justify-between cai-gap-4">
          <div className="cai-flex cai-min-w-[180px] cai-max-w-[300px] cai-flex-col cai-rounded-md cai-bg-cai-sc-secondary cai-p-3">
            <p className="cai-my-1 cai-font-bold">Data Explorer</p>
            <p className="cai-flex-1 cai-text-[13px]">
              Effortlessly query your data and create compelling visualizations
              with our intuitive AI tool. Turn complex data into clear insights
              in seconds.
            </p>
            <div className="cai-mt-2 cai-flex cai-justify-end">
              <button
                className="cai-flex cai-items-center cai-rounded cai-border-0 cai-bg-cai-sc-button-primary cai-px-4 cai-py-2 cai-text-[#EEE] hover:cai-opacity-85"
                onClick={() => appStore.globalStore.navigate("/data-explorer")}
              >
                <CanvasIcon className="cai-mr-2 cai-inline-block cai-h-[24px] cai-w-[24px]" />
                Canvas
              </button>
            </div>
          </div>
          <div className="cai-flex cai-min-w-[180px] cai-max-w-[300px] cai-flex-col cai-rounded-md cai-bg-cai-sc-secondary cai-p-3">
            <p className="cai-my-1 cai-font-bold">Dashboards</p>
            <p className="cai-flex-1 cai-text-[13px]">
              Create intelligent and adaptive dashboards with ease using our
              tool, which allows you to compile and customize visualizations.
            </p>
            <div className="cai-mt-2 cai-flex cai-justify-end">
              <button
                className="cai-flex cai-items-center cai-rounded cai-border-0 cai-bg-cai-sc-button-primary cai-px-4 cai-py-2 cai-text-[#EEE] hover:cai-opacity-85"
                onClick={() => appStore.globalStore.navigate("/canvas")}
              >
                <DashboardIcon className="cai-mr-2 cai-inline-block cai-h-[24px] cai-w-[24px]" />
                Dashboards
              </button>
            </div>
          </div>
          <div className="cai-flex cai-min-w-[180px] cai-max-w-[300px] cai-flex-col cai-rounded-md cai-bg-cai-sc-secondary cai-p-3">
            <p className="cai-my-1 cai-font-bold">Widgets</p>
            <p className="cai-flex-1 cai-text-[13px]">
              View and interact with all existing Visualizations available in
              the Canvas AI platform.
            </p>
            <div className="cai-mt-2 cai-flex cai-justify-end">
              <button
                className="cai-flex cai-items-center cai-rounded cai-border-0 cai-bg-cai-sc-button-primary cai-px-4 cai-py-2 cai-text-[#EEE] hover:cai-opacity-85"
                onClick={() => appStore.globalStore.navigate("/widgets")}
              >
                <WidgetIcon className="cai-mr-2 cai-inline-block cai-h-[24px] cai-w-[24px]" />
                Widgets
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);
